import "./contest-dates-tile.scss";

import React from "react";

const ContestDatesTile = ({ items, title, thumbnail, color }) => {
   return (
      <div className="contest-dates-tile">
         <div
            className="contest-dates-tile__thumbnail"
            style={{ backgroundImage: `url(${thumbnail})` }}
         />
         <div
            className="contest-dates-tile__content"
            style={{ backgroundColor: color }}
         >
            <h3 className="contest-dates-tile__title">{title}</h3>
            <div className="contest-dates-tile__divider" />
            {items?.map((item, index) => {
               return (
                  <div className="contest-dates-tile__single-date" key={index}>
                     <div className="contest-dates-tile__date">
                        <img
                           src={require("assets/icons/calendar.svg").default}
                           className="contest-dates-tile__date-ico"
                           alt="Kalendarz"
                        />
                        {item.date}
                     </div>
                     <div className="contest-dates-tile__text">{item.desc}</div>
                  </div>
               );
            })}
         </div>
      </div>
   );
};

export default ContestDatesTile;
