import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import ContestDatesTile from "components/ContestDatesTile";

const ContestDatesPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   const contests = pageContext.data.contests;

   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="for-who-section">
            <div className="container">
               <SectionTitle title="Terminy konkursów" />
               <div className="row">
                  {contests.map((item, index) => (
                     <div className="col-xl-4 col-md-6" key={index}>
                        <ContestDatesTile
                           color={item.color}
                           thumbnail={
                              item.image
                                 ? item.image.sourceUrl
                                 : require("assets/images/news-placeholder.jpg")
                                      .default
                           }
                           title={item.title}
                           items={item.contests}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default ContestDatesPage;
